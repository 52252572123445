<template>
  <div>
    <div class="label">头像</div>
    <div class="avatar">
      <a-avatar :src="avatar" :size="96" />
    </div>
    <div class="center">
      <input
        ref="upload"
        type="file"
        @change="onChange"
        style="display: none"
        accept="image/*"
      />
      <a-button icon="upload" @click="onClick" :loading="loading">
        更换头像</a-button
      >
    </div>
  </div>
</template>
  
  <script>
import { upload } from "@/api/upload";
export default {
  data() {
    return {
      avatar: "",
      loading: false,
    };
  },

  mounted() {
    this.avatar = window.localStorage.getItem("avatar");
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      if (files.length === 0) return;

      const data = new FormData();
      data.append("files", files[0]);

      this.loading = true;

      upload(data)
        .then((res) => {
          console.log("upload res", res);
          if (Array.isArray(res) && res.length > 0) {
            window.localStorage.setItem("avatar", res[0].completePath);
            window.location.reload();
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    //   uploaded(arr) {
    //     if (arr.length > 0) {
    //       this.updateAvatar(arr[0].completePath);
    //     }
    //   },

    //   ...mapMutations("auth", ["setUser"]),

    //   updateAvatar(path) {
    //     const params = {
    //       ...this.user,
    //       header: path,
    //     };

    //     console.log("params", params);

    //     editBaseInfo(params).then((res) => {
    //       console.log("res", res);
    //       this.setUser(params);
    //     });
    //   },
  },
};
</script>
  
  <style lang="less" scoped>
.label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 40px;
}
.avatar {
  margin-bottom: 12px;
}
</style>