<template>
  <div class="card">
    <div class="form">
      <a-form-model :colon="false">
        <a-form-model-item label="姓名">
          <a-input :value="name" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="手机号码">
          <a-input :value="mobile" :disabled="true" />
        </a-form-model-item>
        <a-form-model-item label="邮箱">
          <a-input :value="email" :disabled="true" />
        </a-form-model-item>
      </a-form-model>
    </div>

    <Avatar />
  </div>
</template>
  
  <script>
import Avatar from "./avatar.vue";

export default {
  components: { Avatar },

  data() {
    return {
      name: "",
      mobile: "",
      email: "",
    };
  },
  mounted() {
    this.name = window.localStorage.getItem("name");
    this.mobile = window.localStorage.getItem("mobile");
    this.email = window.localStorage.getItem("email");
  },
};
</script>
  
  <style lang="less" scoped>
.card {
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;
  display: flex;
  gap: 48px;
}

.form {
  width: 340px;
}
</style>